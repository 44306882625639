<template>
  <div>
    <el-table
      :header-cell-style="cellHeaderStyle"
      :cell-style="cellStyle"
      class="index-list-table"
      :data="tableData"
      :height="$route.name=='offlineCourse'?`calc(100vh - 348px)`:`calc(100vh - 390px)`"
    >
      <el-table-column prop="courseName" label="课程名称" width="180"></el-table-column>
      <el-table-column prop="courseCode" label="课程编号"></el-table-column>
      <el-table-column prop="courseType" label="课程类型" width="120"></el-table-column>
      <el-table-column prop="courseType" label="课程分类" width="120" v-if="type == 'courseManage'">
        <template slot-scope="{ row }">
          <div class="symbol">
            {{row.type == 1?'线上课程':row.type == 2?'线下课程':'—'}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="status" label="发布状态" v-if="type === 'online'||type == 'courseManage'">
        <template slot-scope="{ row }">
          <div class="symbol" v-if="type === 'online' || (type == 'courseManage'&&row.type ==1)">
            <span
              :class="{
                orange: row.status == 2,
                green: row.status == 1,
              }"
              ></span
            >
            {{row.status == 1?'已发布':row.status == 2?'未发布':'--'}}
          </div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="groupName" label="课程分组"></el-table-column>
      <el-table-column prop="updateName" label="最近更新人"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
      <el-table-column prop="createName" label="创建人"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>

      <el-table-column label="操作" width="144" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="text-btn mr24"
            @click="_courseDetail(scope.row)"
            >详情</el-button
          >
          <el-button type="text" class="text-btn mr24" @click="_deleteCourse(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import tableFirstCol from '@/mixins/tableFirstCol'
export default {
  mixins: [tableFirstCol],
  props: {
    tableData: {
      default: () => [],
      type: Array
    },
    type: {
      default: '',
      type: String
    }
  },
  mounted () {
    console.log(this.$route.name, 'name')
  },
  methods: {
    _courseDetail (row) {
      this.$emit('courseDetail', row)
    },
    _deleteCourse (row) {
      this.$emit('deleteCourse', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.symbol {
  display: flex;
  align-items: center;
  span {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 4px;
  }
}
.green {
  background-color: #10c038;
}
.orange {
  background-color: #ff931d;
}
</style>
